import {SystemProps, Box, Flex, Icon} from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import {Button, Container, Heading, Modal, RichText, variants} from '~components';
import { DynamicSectionContainer } from '..';
import { storyblokEditable } from '@storyblok/react';
import Grid from '~components/common/Grid';
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Close } from '~components/common/Icons';

type TextImageProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const TextImage = ({ content, first, ...props }: TextImageProps) => {
  const { title_bold, title, text, image, reverse, text_image_type, background, text_background, break_bold, cta_label, cta_link, cta_link_anchor, cta_type, cta_unstyled_label, cta_unstyled_link, cta_unstyled_link_anchor, without_background, text_color, show_full_image, clickable_image, bottom_links_block } = content;
  const link = {
    label: cta_label,
    link: cta_link,
    anchor: cta_link_anchor,
    variant: cta_type
  }
  const unstyledLink = {
    label: cta_unstyled_label,
    link: cta_unstyled_link,
    anchor: cta_unstyled_link_anchor
  }

  const [ modalOpen, setModalOpen ] = useState(false);
  
  return (
    <DynamicSectionContainer bg={background} {...storyblokEditable(content)} {...props}>
      <Container
        position="relative"
        overflow="hidden"
        minHeight={["auto", "auto", "auto", "640px"]}
        px={[0, 0, 10, "130px"]}
        my={[2, 2, 0, 0]}
      >
        <Grid alignItems="center" minHeight={["auto", "auto", "auto", text_image_type === "small" ? "500px" : "640px"]}>
          {!show_full_image ?
            <Box
              gridColumn={["span 6", "span 8", `${reverse ? "5" : "1"} / span 6`, `${reverse ? "5" : "1"} / span 8`]}
              gridRow={1}
              height={text_image_type === "medium" ? "640px" : text_image_type === "small" ? "500px" : "100%"}
              marginLeft={reverse ? 0 : [
                0, 0, -10, "-130px"
              ]}
              marginRight={reverse ? [
                0, 0, -10, "-130px"
              ] : 0}
            >
              <Image src={image?.filename} alt={image?.alt} height={text_image_type === "medium" ? "640px" : text_image_type === "small" ? "500px" : "100%"}/>
            </Box>
          : <Button
              variant="unstyled"
              display="block"
              onClick={clickable_image ? () => setModalOpen(true) : undefined}
              pointerEvents={clickable_image ? "auto" : "none"}
              gridColumn={["span 6", "span 8", `${reverse ? "7" : "1"} / span 4`, `${reverse ? "7" : "1"} / span 6`]}
              marginLeft={reverse ? 0 : [
                0, 0, -10, "-130px"
              ]}
              marginRight={reverse ? [
                0, 0, -10, "-130px"
              ] : 0}
            >
              <Image src={image?.filename} alt={image?.alt} height="100%" fit='contain' />
            </Button>
          }
          <Modal isOpen={modalOpen} width={["90vw"]} maxWidth={"90vw"} maxHeight={"90vh"} padding="0 0 10px">
            <Box position={"relative"} height={"70vh"} css={css({
              "& > div": {
                // ignore error, this is a styled-components issue
                // @ts-ignore
                "position": "static !important",
              }
            })}>
              <Button
                variant="unstyled"
                display="block"
                onClick={() => setModalOpen(false)}
                position="absolute"
                top="0"
                right="0"
                zIndex={2}
                padding="10px"
              >
                <Icon icon={Close} />
              </Button>
              <Image src={image?.filename} alt={image?.alt} height="100%" fit='contain' />
            </Box>
          </Modal>
          <Flex
            flexDirection={"column"}
            gridColumn={
              show_full_image 
              ? ["span 6", "span 8", `${reverse ? "1" : "7"} / span 6`, `${reverse ? "1 " : "7"} / span 6`]
              : ["span 6", "span 8", `${reverse ? "1" : "5"} / span 6`, `${reverse ? "1 " : "7"} / span 6`]}
            gridRow={[2, 2, 1]}
            bg={without_background ? "none" : text_background !== '' && typeof text_background !== 'undefined' ? text_background : background !== "secondary100" ? "white" : "none"}
            zIndex={1}
            height={"fit-content"}
            p={without_background ? ["40px 0"] : ["40px 16px", "40px 16px", "40px 40px", "40px 40px", "40px 40px"]}
            my={[0, 0, 5]}
          >
            <Heading
              variant={"h2"}
              color={text_color}
              fontSize={['18px', '24px', '24px', '28px', '32px', '40px']}
              lineHeight="140%"
            >
              {title}{' '}
              <Box as="span" display={break_bold ? "block" : 'inline'}  maxWidth={break_bold ? "750px" : 'auto'} fontWeight="bold">
                {title_bold}
              </Box>
            </Heading>
            {text?.content?.[0]?.content?.length > 0
              ? <StyledRichText><RichText text={text} textColor={text_color} lineHeight="140%" mt={2}/></StyledRichText>
              : null}
            {typeof link.label === 'string' && link.label !== '' ? <Flex gridColumnGap={2} mt={[2, 3, 4, 5]} flexWrap="wrap">
              {typeof link.anchor === "string" && link.anchor !== "" ?
                <Button
                  variant={link.variant in variants ? link.variant : "link"}
                  href={typeof link.link?.story?.full_slug !== "undefined" ? link.link?.story?.full_slug + "#" + link.anchor : link.link?.cached_url + "#" + link.anchor}
                >
                  {link.label}
                </Button>
                :
                <Button
                  variant={link.variant in variants ? link.variant : "link"}
                  href={typeof link.link?.story?.full_slug !== "undefined" ? link.link?.story?.full_slug : link.link?.cached_url}
                >
                  {link.label}
                </Button>
              }
              {typeof unstyledLink.label === "string" && unstyledLink.label !== "" ? (
                <>
                  {typeof unstyledLink.anchor === "string" && unstyledLink.anchor !== "" ?
                    <Button
                      variant="unstyled"
                      color={text_color}
                      href={typeof unstyledLink.link?.story?.full_slug !== "undefined" ? unstyledLink.link?.story?.full_slug + "#" + unstyledLink.anchor: unstyledLink.link?.cached_url + "#" + unstyledLink.anchor}
                    >
                      {unstyledLink.label}
                    </Button>
                    :
                    <Button
                      variant="unstyled"
                      color={text_color}
                      href={typeof unstyledLink.link?.story?.full_slug !== "undefined" ? unstyledLink.link?.story?.full_slug : unstyledLink.link?.cached_url}
                    >
                      {unstyledLink.label}
                    </Button>
                  }
                </>) : null}
            </Flex> : null}
            {Array.isArray(bottom_links_block) && bottom_links_block.length !== 0 ? <Flex gridColumnGap={2} mt={[2, 3, 4, 5]} flexWrap="wrap">
              {bottom_links_block.map((link) => (
                <>
                  {typeof link?.anchor === "string" && link?.anchor !== "" ?
                    <Button
                      variant="underlined"
                      color={typeof link?.text_color !== "undefined" ? link?.text_color : "black"}
                      href={typeof link?.story?.full_slug !== "undefined" ? link?.story?.full_slug + '#' + link?.anchor : link?.url?.cached_url + '#' + link?.anchor}
                    >
                      {link?.label}
                    </Button>
                    :
                    <Button
                      variant="underlined"
                      color={typeof link?.text_color !== "undefined" ? link?.text_color : "black"}
                      href={typeof link?.story?.full_slug !== "undefined" ? link?.story?.full_slug : link?.url?.cached_url}
                    >
                      {link?.label}
                    </Button>
                  }
                </>
              ))}
            </Flex> : null}
          </Flex>
        </Grid>
      </Container>
    </DynamicSectionContainer>
  );
};

export const StyledRichText = styled(Box)`
  ol {
    list-style: url('/images/checkmark.svg') outside;
    li {
      padding-left: 10px;
    }
  }
`;
