import { useRef, useEffect, useState } from 'react';
import { SystemProps, Box, css } from '@storyofams/react-ui';
import qs from 'qs';

type VimeoVideoProps = {
  content: {
    url: string;
  };
  autoplay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  playing?: boolean;
  playsinline?: boolean;
  onClick?(): void;
  fullWidth?: boolean;
  disableResize?: boolean;
} & SystemProps;

export const VimeoVideo = ({
  content,
  fullWidth,
  ...props
}: VimeoVideoProps) => {
  const videoBgAspectRef = useRef(null);
  const videoBg = useRef(null);
  const [width, setWidth] = useState('100%');
  const disableResize = !!props?.disableResize
  let timeoutId;

  const videobgEnlarge = () => {
    let windowWidth = window.innerWidth > 1440 ? 1440 : window.innerWidth;
    let windowAspect = window.innerHeight / windowWidth;
    let videoAspect =
      videoBgAspectRef?.current?.clientHeight /
      videoBgAspectRef?.current?.clientWidth;
    let bgAspect =
      videoBg?.current?.clientHeight / videoBg?.current?.clientWidth;

    const newWidth = fullWidth
      ? windowAspect > videoAspect
        ? `${(windowAspect / videoAspect) * 100}%`
        : '100%'
      : `${(bgAspect / videoAspect) * 100}%`;

    setWidth(newWidth);
  };

  const handleResize = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(videobgEnlarge, 100);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    if (disableResize) {
      return;
    }
    handleResize();
  }, [videoBgAspectRef]);

  return (
    <Box
      height="100%"
      css={css({
        '.videobg': {
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          background: 'black',
        },
        '.videobg-width': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: '-9999px',
          right: '-9999px',
          margin: 'auto',
        },
        '.videobg-aspect': {
          position: 'absolute',
          width: '100%',
          height: 0,
          top: '-9999px',
          bottom: '-9999px',
          margin: 'auto',
          // TODO adjust pb when vimeo video has uncommon aspect ratio
          paddingBottom: '56.25%',
          overflow: 'hidden',
        },

        '.videobg-make-height': {
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },

        '.videobg-hide-controls': {
          boxSizing: 'content-box',
        },

        '.videobg iframe': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: '0 none',
        },
      })}
      {...props}
    >
      <div className="videobg" ref={videoBg}>
        <div className="videobg-width" style={{ width, minWidth: '100%' }}>
          <div className="videobg-aspect" ref={videoBgAspectRef}>
            <div className="videobg-make-height">
              {!props?.controls && (
                <Box
                  position="absolute"
                  top={0}
                  bottom={0}
                  left={0}
                  right={0}
                  zIndex={2}
                />
              )}
              <iframe
                src={`https://player.vimeo.com/video/${content.url.replace(
                  'https://vimeo.com/',
                  '',
                )}?title=0&byline=0&portrait=0&${qs.stringify({
                  autoplay: props?.autoplay ? 1 : 0,
                  loop: props?.loop ? 1 : 0,
                  muted: props?.muted ? 1 : 0,
                  controls: props?.controls ? 1 : 0,
                  playing: props?.playing ? 1 : 0,
                  playsinline: props?.playsinline ? 1 : 0,
                })}`}
                frameBorder="0"
                allowFullScreen
                title="Philips"
                allow="autoplay; fullscreen; picture-in-picture"
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
