import React, { useMemo, useState } from 'react';
import { SystemProps, Box, Flex } from "@storyofams/react-ui";

import { Button, Container, Heading, RichText, variants } from "~components";
import { VideoOverlay } from '~components/VideoOverlay';
import { storyblokEditable } from '@storyblok/react';

import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from "react-player/types/lib";
import { Video } from "~components/dynamic/sections/VideoSection";
import { Image } from "@storyofams/storyblok-toolkit";
import Grid from "~components/common/Grid";
import { StyledRichText } from "~components/dynamic/sections/TextImage";
import { DynamicSectionContainer } from '../DynamicSectionContainer';
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

type ContentProps = {
  content: any;
} & SystemProps;

export const TextVideo = ({ content, ...props }: ContentProps) => {
  const videoUrl = !!content?.video ? content.video : content?.url;
  const renderAsBackground = !!content?.render_as_background ? content?.render_as_background : false;
  const { title_bold, title, text, video_text, reverse, break_bold, cta_label, cta_link, cta_link_anchor, cta_type, cta_unstyled_label, cta_unstyled_link, cta_unstyled_link_anchor, text_color, text_image_ratio, inactive_regions } = content;
  const link = {
    label: cta_label,
    link: cta_link,
    anchor: cta_link_anchor,
    variant: cta_type
  }
  const unstyledLink = {
    label: cta_unstyled_label,
    link: cta_unstyled_link,
    anchor: cta_unstyled_link_anchor
  }

  const { video_columns, text_columns } = useMemo(() => {
    const columns = {
      video_columns: [],
      text_columns: []
    }
    if (reverse) {
      if (text_image_ratio === "4x8") {
        columns.video_columns = ["span 6", "span 8", `7 / span 6`, `5 / span 8`]
        columns.text_columns = ["span 6", "span 6", `1 / span 4`, `1 / span 4`]
      } else {
        columns.video_columns = ["span 6", "span 8", `7 / span 6`, `7 / span 6`]
        columns.text_columns = ["span 6", "span 6", `1 / span 4`, `1 / span 6`]
      }
    } else {
      if (text_image_ratio === "4x8") {
        columns.video_columns = ["span 6", "span 8", `1 / span 4`, `1 / span 4`]
        columns.text_columns = ["span 6", "span 6", `5 / span 6`, `5 / span 8`]
      } else {
        columns.video_columns = ["span 6", "span 8", `1 / span 4`, `1 / span 6`]
        columns.text_columns = ["span 6", "span 6", `5 / span 6`, `7 / span 6`]
      }
    }
    return columns
  }, [reverse])

  return (
    <DynamicSectionContainer {...storyblokEditable(content)} bg={content?.bg}>
      <Container my={10}>
        <Grid alignItems="center">
          <Box
            justifyContent={'left'}
            display={'flex'}
            gridColumn={video_columns}
            gridRow={1}
            height={'auto'}
            flexFlow={'row'}
            flexWrap={'wrap'}
          >
            {video_text ? (
              <Heading
                variant={"h4"}
                color={text_color}
                my={2}
              >
                {video_text}
              </Heading>
            ) : null}
            <Video
              width="100%"
              height="100%"
              position="relative"
              overflow="hidden"
              content={{ url: videoUrl, pt: '56%', disableResize: true }}
              controls={true}
              bg={content?.bg}
              renderAsBackground={renderAsBackground}
              disableResize={true}
              my={[2, 2, 0, 0]}
              {...props}
            />
          </Box>
          <Flex
            flexDirection={"column"}
            gridColumn={text_columns}
            gridRow={[2, 2, 1]}
            zIndex={1}
            height="fit-content"
            p={["40px 16px", "40px 16px", "40px 40px", "40px 40px", "40px 40px"]}
          >
            <Heading
              variant={"h2"}
              color={text_color}
              fontSize={['18px', '24px', '24px', '28px', '32px', '40px']}
              lineHeight="140%"
            >
              {title}{' '}
              <Box as="span" display={break_bold ? "block" : 'inline'}  maxWidth={break_bold ? "750px" : 'auto'} fontWeight="bold">
                {title_bold}
              </Box>
            </Heading>
            {text?.content?.[0]?.content?.length > 0
              ? <StyledRichText><RichText text={text} textColor={text_color} lineHeight="140%" mt={2}/></StyledRichText>
              : null}
            {typeof link.label === 'string' && link.label !== '' ? <Flex gridColumnGap={2} mt={[2, 3, 4, 5]} flexWrap="wrap">
              {typeof link.anchor === "string" && link.anchor !== "" ?
                <Button
                  variant={link.variant in variants ? link.variant : "link"}
                  href={typeof link.link?.story?.full_slug !== "undefined" ? link.link?.story?.full_slug + "#" + link.anchor : link.link?.cached_url + "#" + link.anchor}
                >
                  {link.label}
                </Button>
                :
                <Button
                  variant={link.variant in variants ? link.variant : "link"}
                  href={typeof link.link?.story?.full_slug !== "undefined" ? link.link?.story?.full_slug : link.link?.cached_url}
                >
                  {link.label}
                </Button>
              }
              {typeof unstyledLink.label === "string" && unstyledLink.label !== "" ? (
                <>
                  {typeof unstyledLink.anchor === "string" && unstyledLink.anchor !== "" ?
                    <Button
                      variant="unstyled"
                      color={text_color}
                      href={typeof unstyledLink.link?.story?.full_slug !== "undefined" ? unstyledLink.link?.story?.full_slug + "#" + unstyledLink.anchor: unstyledLink.link?.cached_url + "#" + unstyledLink.anchor}
                    >
                      {unstyledLink.label}
                    </Button>
                    :
                    <Button
                      variant="unstyled"
                      color={text_color}
                      href={typeof unstyledLink.link?.story?.full_slug !== "undefined" ? unstyledLink.link?.story?.full_slug : unstyledLink.link?.cached_url}
                    >
                      {unstyledLink.label}
                    </Button>
                  }
                </>) : null}
            </Flex> : null}
          </Flex>
        </Grid>
      </Container>
    </DynamicSectionContainer>
  );
};
