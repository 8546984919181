import { useState } from 'react';
import { SystemProps, Box, css } from '@storyofams/react-ui';

import { Container, Image } from '~components';
import { VideoOverlay } from '~components/VideoOverlay';
import { VimeoVideo } from '~components/VimeoVideo';
import { storyblokEditable } from '@storyblok/react';

import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from "react-player/types/lib";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

type VideoProps = {
  content: any;
  hasOverlay?: boolean;
} & SystemProps;

export const VideoSection = ({ content, hasOverlay, ...props }: VideoProps) => {
  const [isOpen, setOpen] = useState(false);
  const videoUrl = !!content?.video ? content.video : content?.url;
  const renderAsBackground = !!content?.render_as_background ? content?.render_as_background : false;

  return (
    <Box {...storyblokEditable(content)} bg={content?.bg}>
      <Container my={10}>
        <Video
          content={{ url: videoUrl }}
          loop
          controls={true}
          hasOverlay={hasOverlay}
          bg={content?.bg}
          renderAsBackground={renderAsBackground}
          {...props}
        />

        {hasOverlay && (
          <Box
            onClick={() => setOpen(true)}
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            zIndex={2}
          />
        )}
      </Container>

      {hasOverlay && (
        <VideoOverlay isOpen={isOpen} setOpen={setOpen} src={videoUrl} />
      )}
    </Box>
  );
};

export const Video = ({ content, hasOverlay = false, renderAsBackground = false, ...props }) => {
  const videoUrl = !!content?.video ? content.video : content?.url;
  const pt = !!content?.pt ? content.pt : '56.25%'
  const disableResize = !!content?.disableResize ? content.disableResize : false

  if (
    videoUrl.includes('vimeo')
  ) {
    return renderAsBackground === false ?
      <Box
        mx="auto"
        display="flex"
        justifyContent="center"
        bg="secondary"
        borderRadius="md"
        position="relative"
        css={css({
          width: '100%',
          pt: pt,
          '> div': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100% !important',
            height: '100% !important',
          },
          iframe: {
            width: '100%',
            height: '100%',
          },
        })}
        {...props}
      >
        <VimeoVideo {...props} disableResize={disableResize} content={{ url: videoUrl }}/>
      </Box>
      : <VimeoVideo {...props} disableResize={disableResize} content={{ url: videoUrl }}/>;
  }

  const videoId = content?.url?.split('v=')[1]?.split('&')[0];

  return (
    <Box
      mx="auto"
      display="flex"
      justifyContent="center"
      bg="secondary"
      borderRadius="md"
      position="relative"
      css={css({
        width: '100%',
        pt: '56.25%',
        '> div': {
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100% !important',
          height: '100% !important',
        },
        iframe: {
          width: '100%',
          height: '100%',
        },
      })}
      {...props}
    >
      {props?.autoplay || !hasOverlay ? (
        <>
          {content?.url && (
            <ReactPlayer
              url={content?.url}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    controls: props.controls ? 1 : 0,
                  },
                },
                vimeo: {
                  playerOptions: {
                    controls: props.controls,
                  },
                },
              }}
              {...props}
            />
          )}
        </>
      ) : (
        <Image
          width="100%"
          height="100%"
          layout="fill"
          src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
          unoptimized
          objectFit="cover"
        />
      )}
    </Box>
  );
};
