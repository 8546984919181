import { DialogOverlay } from '@reach/dialog';
import { Icon } from '@storyofams/react-ui';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import '@reach/dialog/styles.css';

import { Button } from './common/Button';
import { FixedRatio } from './common/FixedRatio';
import { Close } from './common/Icons';
import { LegacyContainer } from './common/Layout/Container';

const Overlay = styled(DialogOverlay)`
  display: flex;
  align-items: center;
  justify-content: center;
  && {
    z-index: ${(p) => p.theme.zIndices.modal};
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const VideoOverlay = ({ src, isOpen, setOpen }) => (
  <>
    {isOpen && (
      <Overlay isOpen onDismiss={() => setOpen(false)}>
        <Button
          variant="unstyled"
          justifyContent="center"
          alignItems="center"
          width={30}
          height={30}
          position="absolute"
          right={[3, 3, 3, '106px']}
          top={[3, 3, 3, 10]}
          zIndex={2}
          onClick={() => setOpen(false)}
        >
          <Icon
            icon={Close}
            fontSize={3}
            color="white"
            size="24px"
            cursor="pointer"
          />
        </Button>

        <LegacyContainer width="100%" maxWidth="maxWidth" pt={10} pb={10}>
          <FixedRatio ratio={[[16, 9]]} width="100%">
            <ReactPlayer
              width="100%"
              height="100%"
              style={{ padding: 0 }}
              url={src}
              controls
              responsive
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    controls: true,
                  },
                },
                vimeo: {
                  playerOptions: {
                    controls: true,
                    responsive: true,
                  },
                },
              }}
            />
          </FixedRatio>
        </LegacyContainer>
      </Overlay>
    )}
  </>
);
